<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      calculate-widths
      :items="items"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      @item-expanded="({value, item}) => value && fetchSubTable(item)"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      hide-default-footer
    >
      <template v-slot:[`item.no`]="{index}">
        <span>
          {{ index + 1}}
        </span>
      </template>
      <template v-slot:[`item.isMandatory`]="{item}">
        <span>
          {{ item.isMandatory ? $_strings.common.YES : $_strings.common.NO}}
        </span>
      </template>
      <template v-slot:[`item.createdBy`]="{item}">
        <span>
          {{item.createdBy}}
        </span>
        <br>
        <span class="text-caption">
          {{getDateShort(item.createdAt)}}
        </span>
      </template>
      <template v-slot:[`item.actionColumn`]="{item}">
        <v-btn color="primary lighten-1" small :to="`/main/kategori/edit/${item.id}`">
          ubah
        </v-btn>
      </template>
      <template
        v-slot:expanded-item="{ headers, item: subItem }"
      >
        <template v-if="subItem.subItemTableLoading">
          <td :colspan="headers.length">
            <v-row justify="center">
              <v-col cols="auto">
                <v-progress-circular
                  class="mx-auto"
                  color="primary"
                  size="30"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-else-if="subItem.subItemTable">
          <!-- <td v-if="!isMobile"></td> -->
          <td
            :colspan="headers.length"
            class="px-0"
          >
            <v-data-table
              :headers="headers"
              :items="subItem.subItemTable"
              item-key="name"
              hide-default-header
              hide-default-footer
              :items-per-page="subItem.subItemTable.length"
            >
              <template v-slot:header="{props}">
                <th
                  v-for="(subHeader, index) in props.headers"
                  :key="index"
                  :style="{width: subHeader.width}"
                ></th>
              </template>
              <template v-slot:[`item.descriptions`]="{item}">
                <span>
                  <a v-if="item.attachmentUrl" @click="openDocument(item)">{{item.attachmentName}}</a>
                  <span v-else class="red--text">{{item.attachmentName}}</span>
                </span>
              </template>
            </v-data-table>
          </td>
          <!-- <td v-else>
            tidak ada data
          </td> -->
        </template>
      </template>
    </v-data-table>
    <dialog-view-image :visible="dialogViewImage" @setDialog="setDialog" :imageUrl="imageSelected"/>
    <dialog-view-pdf :visible="dialogViewPdf" @setDialog="setDialog" :urlPdf="urlPdfSelected"/>
  </v-container>
</template>

<script>
export default {
  name: 'component-LegalDocumentList',
  data() {
    return {
      isAgree: false,
      items: [],
      dialogViewImage: false,
      dialogViewPdf: false,
      imageSelected: '',
      urlPdfSelected: '',
      headers: [
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.legalDocument.NO_TABLE_HEADER_LABEL,
          value: 'no',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.legalDocument.NAME_TABLE_HEADER_LABEL,
          value: 'descriptions',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.legalDocument.MANDATORY_TABLE_HEADER_LABEL,
          value: 'isMandatory',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.legalDocument.DESCRIPTION_TABLE_HEADER_LABEL,
          value: '',
          class: 'white--text primary',
          sortable: false,
        },
      ],
      formSubmission: [],
      expanded: [],
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      totalItems: 0,
      subItemTableLoading: false,
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchListLegalDocument();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      }));
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    fetchListLegalDocument() {
      this.$root.$loading.show();
      const { page } = this.pagination;
      const _page = (page || this.pagination.page) - 1;
      const size = this.pagination.itemsPerPage;
      this.$_services.legalDocument.getListLegalDocument()
        .then((result) => {
          const dataLegalDocument = [];
          result.data.map((legalDocument) => {
            if (legalDocument.isShow) {
              // this.push(legalDocument);
              dataLegalDocument.push(legalDocument);
            }
            return dataLegalDocument;
          });
          this.$set(this, 'items', dataLegalDocument);
        }).finally((i) => {
          this.$root.$loading.hide();
        });
    },
    fetchSubTable(item) {
      if (item.subItemTable) return;
      this.$set(item, 'subItemTableLoading', true);
      const documentSupportId = item.id;
      this.$_services.legalDocument.getListAttachment(documentSupportId)
        .then((res) => {
          let contents = [];
          if (res.data) {
            contents = res.data.map((i) => ({ ...i, type: 'Sub Table' }));
          } else {
            contents.push({
              attachmentName: 'Tidak ada data',
            });
          }
          this.$set(item, 'subItemTable', contents);
        })
        .finally(() => {
          this.$set(item, 'subItemTableLoading', false);
        });
    },
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
    openDocument(item) {
      const { fileType, attachmentUrl } = item;
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        this.dialogViewImage = true;
        this.imageSelected = attachmentUrl;
        return;
      }
      if (fileType === 'pdf') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = attachmentUrl;
        return;
      }
      window.open(attachmentUrl);
    },
  },
  mounted() {
    this.fetchListLegalDocument();
  },
};
</script>

<style>
</style>
